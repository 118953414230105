var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-6 pa-2"},[_c('v-card-title',{staticClass:"align-start"},[_c('span',{staticClass:"font-weight-semibold text-base"},[_vm._v("Statistiques par Article")])]),(_vm.items.length === 0)?_c('span',[_vm._v(_vm._s(_vm.noDataText))]):_vm._e(),_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length > 0),expression:"items.length > 0"}],staticClass:"v-data-table-with-borders ma-3 elevation-1",attrs:{"headers":_vm.filtredHeaders,"items":_vm.sortedItems,"hide-default-header":true,"items-per-page":20,"footer-props":{ itemsPerPageOptions: [20, 50, 100] },"dense":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var props = ref.props;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',{staticClass:"text-center parent-header",attrs:{"colspan":_vm.showRmrId ? 7 : 6}},[_vm._v(" Infos Globales ")]),_c('th',{staticClass:"text-center parent-header",attrs:{"colspan":"2"}},[_vm._v("Sessions")]),_c('th',{staticClass:"text-center parent-header",attrs:{"colspan":"2"}},[_vm._v("CA")]),_c('th',{staticClass:"text-center parent-header",attrs:{"rowspan":"2"}},[_vm._v("Actions")])]),_c('tr',_vm._l((props.headers),function(header){return _c('th',{key:header.text,class:[
              'text-center',
              'child-header',
              { sortable: header.sortable } ],on:{"click":function($event){header.sortable && _vm.sort(header.value)}}},[_vm._v(" "+_vm._s(header.text)+" "),(header.sortable)?_c('v-icon',[_vm._v(" "+_vm._s(props.options.sortBy === header.value ? props.options.sortDesc ? "mdi-arrow-down" : "mdi-arrow-up" : "")+" ")]):_vm._e()],1)}),0)])]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',[(_vm.showRmrId)?_c('td',[_vm._v(_vm._s(item.id))]):_vm._e(),_c('td',[_vm._v(_vm._s(item.site))]),_c('td',[_vm._v(_vm._s(item.date))]),_c('td',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('span',_vm._g({},on),[_c('a',{attrs:{"href":item.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.truncate(item.title, 50))+" ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])])],1),_c('td',[_vm._v(_vm._s(item.category))]),_c('td',[_vm._v(_vm._s(_vm.formatNumber(item.sessions)))]),_c('td',[_vm._v(_vm._s(_vm.formatNumber(item.ca)))]),_c('td',{attrs:{"colspan":"2"}},[_c('PercentChart',{attrs:{"value1":item.sessions_before,"value2":item.sessions_after,"color1":"#777eff","color2":"#444eff"}})],1),_c('td',{attrs:{"colspan":"2"}},[_c('PercentChart',{attrs:{"value1":item.ca_before,"value2":item.ca_after,"color1":"#ffbc50","color2":"#fb9e0b"}})],1),_c('td',{staticClass:"text-center"},[_c('a',{attrs:{"target":"_blank"}},[_c('v-icon',{attrs:{"color":"primary"},nativeOn:{"click":function($event){_vm.dialogModel.dialog = !_vm.dialogModel.dialog;
                _vm.dialogModel.id = item.id;
                _vm.dialogModel.url = item.url;
                _vm.dialogModel.title = item.title;}}},[_vm._v(" "+_vm._s(_vm.icons.eye)+" ")])],1)])])]}}])}),_c('v-dialog',{attrs:{"width":"70vw"},model:{value:(_vm.dialogModel.dialog),callback:function ($$v) {_vm.$set(_vm.dialogModel, "dialog", $$v)},expression:"dialogModel.dialog"}},[_c('post-infos-modal',{attrs:{"dialogOpen":_vm.dialogModel.dialog,"id":_vm.dialogModel.id,"url":_vm.dialogModel.url,"title":_vm.dialogModel.title},on:{"openDialog":_vm.openDialog}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }