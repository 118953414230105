<template>
  <v-card class="my-6 pa-2">
    <v-card-title class="align-start">
      <span class="font-weight-semibold text-base"
        >Statistiques par Article</span
      >
    </v-card-title>
    <span v-if="items.length === 0">{{ noDataText }}</span>
    <v-data-table
      v-show="items.length > 0"
      :headers="filtredHeaders"
      :items="sortedItems"
      :hide-default-header="true"
      :items-per-page="20"
      :footer-props="{ itemsPerPageOptions: [20, 50, 100] }"
      dense
      class="v-data-table-with-borders ma-3 elevation-1"
    >
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th :colspan="showRmrId ? 7 : 6" class="text-center parent-header">
              Infos Globales
            </th>
            <th colspan="2" class="text-center parent-header">Sessions</th>
            <th colspan="2" class="text-center parent-header">CA</th>
            <th rowspan="2" class="text-center parent-header">Actions</th>
          </tr>
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.text"
              :class="[
                'text-center',
                'child-header',
                { sortable: header.sortable },
              ]"
              @click="header.sortable && sort(header.value)"
            >
              {{ header.text }}
              <v-icon v-if="header.sortable">
                {{
                  props.options.sortBy === header.value
                    ? props.options.sortDesc
                      ? "mdi-arrow-down"
                      : "mdi-arrow-up"
                    : ""
                }}
              </v-icon>
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td v-if="showRmrId">{{ item.id }}</td>
          <td>{{ item.site }}</td>
          <td>{{ item.date }}</td>
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <a :href="item.url" target="_blank">
                    {{ truncate(item.title, 50) }}
                  </a>
                </span>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </td>
          <td>{{ item.category }}</td>
          <td>{{ formatNumber(item.sessions) }}</td>
          <td>{{ formatNumber(item.ca) }}</td>
          <td colspan="2">
            <PercentChart
              :value1="item.sessions_before"
              :value2="item.sessions_after"
              color1="#777eff"
              color2="#444eff"
            />
          </td>
          <td colspan="2">
            <PercentChart
              :value1="item.ca_before"
              :value2="item.ca_after"
              color1="#ffbc50"
              color2="#fb9e0b"
            />
          </td>
          <td class="text-center">
            <a target="_blank">
              <v-icon
                color="primary"
                @click.native="
                  dialogModel.dialog = !dialogModel.dialog;
                  dialogModel.id = item.id;
                  dialogModel.url = item.url;
                  dialogModel.title = item.title;
                "
              >
                {{ icons.eye }}
              </v-icon>
            </a>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogModel.dialog" width="70vw">
      <post-infos-modal
        :dialogOpen="dialogModel.dialog"
        :id="dialogModel.id"
        :url="dialogModel.url"
        :title="dialogModel.title"
        @openDialog="openDialog"
      ></post-infos-modal>
    </v-dialog>
  </v-card>
</template>

<script>
import { Konami, KonamiManager } from "@/utils/konami";
import { truncate } from "@/utils/formatting";
import { formatPercent, formatNumber } from "@/utils/formatting";
import { noDataText } from "@/utils/constants";
import useAxios from "@/hooks/useAxios";
import debounce from "lodash/debounce";
import PercentChart from "@/components/google-discover/republishing-discover-stats/charts/PercentChart";
import { mdiEye } from "@mdi/js";
import PostInfosModal from "@/components/google-discover/republishing-discover-stats/modals/RepublishingPostInfosModal";

export default {
  name: "RepublishingDiscoverStatsTable",
  components: {
    PercentChart,
    PostInfosModal,
  },
  setup() {
    const { isLoading, axiosPost } = useAxios();
    return {
      isLoading,
      axiosPost,
    };
  },
  created() {
    this.konamiManager = new KonamiManager(this, [
      new Konami([68, 69, 86], () => {
        this.showRmrId = !this.showRmrId;
      }),
    ]);
  },
  data() {
    return {
      showRmrId: false,
      noDataText,
      dialogModel: {
        dialog: false,
        id: null,
        url: null,
        title: null,
      },
      headers: [
        { text: "RMR ID", value: "id", sortable: true },
        { text: "Site", value: "site", sortable: true },
        { text: "Date", value: "date", sortable: true },
        { text: "Titre", value: "title", sortable: true },
        { text: "Rubrique", value: "category", sortable: true },
        { text: "Sessions", value: "sessions", sortable: true },
        { text: "CA", value: "ca", sortable: true },
        { text: "Avant", value: "sessions_before", sortable: true },
        { text: "Après", value: "sessions_after", sortable: true },
        { text: "Avant ", value: "ca_before", sortable: true },
        { text: "Après ", value: "ca_after", sortable: true },
      ],
      items: [],
      options: {
        sortBy: "",
        sortDesc: false,
      },
      icons: {
        eye: mdiEye,
      },
    };
  },
  methods: {
    formatNumber,
    formatPercent,
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      if (this.applyFilters && !this.isLoading) {
        const params = {
          dates: this.dates,
          sites: this.sites,
          categories: this.categories,
        };
        const { data } = await this.axiosPost(
          "google-discover/republishing-discover-stats",
          params
        );
        this.items = data.items;
        this.$store.dispatch("common/updateApplyFilters", false);
        this.$store.dispatch("gd/updateGlobalData", data);
      }
    },
    sort(sortBy) {
      if (this.options.sortBy === sortBy) {
        this.options.sortDesc = !this.options.sortDesc;
      } else {
        this.options.sortBy = sortBy;
        this.options.sortDesc = false;
      }
    },
    truncate(str, n) {
      return truncate(str, n);
    },
    openDialog(value) {
      console.log(value);
      this.dialogModel.dialog = value;
    },
  },
  computed: {
    filtredHeaders() {
      if (!this.showRmrId) {
        return this.headers.filter((header) => header.value !== "id");
      } else {
        return this.headers;
      }
    },
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    dates() {
      return this.$store.getters["gd/getDates"];
    },
    sites() {
      return this.$store.getters["gd/getSites"];
    },
    categories() {
      return this.$store.getters["gd/getCategories"];
    },
    sortedItems() {
      if (!this.options.sortBy) {
        return this.items;
      }

      return this.items.slice().sort((a, b) => {
        const sortA = a[this.options.sortBy];
        const sortB = b[this.options.sortBy];

        if (sortA < sortB) return this.options.sortDesc ? 1 : -1;
        if (sortA > sortB) return this.options.sortDesc ? -1 : 1;
        return 0;
      });
    },
  },
  watch: {
    applyFilters() {
      this.debouncedGetData();
    },
    // dates() {
    //   this.debouncedGetData();
    // },
    // sites() {
    //   this.debouncedGetData();
    // },
    sites() {
      return this.$store.getters["gd/getCategories"];
    },
  },
};
</script>
<style scoped>
.v-data-table-with-borders .v-data-table__wrapper td,
.v-data-table-with-borders .v-data-table__wrapper th {
  border-right: 1px solid #e0e0e0;
}

.v-data-table-with-borders .v-data-table__wrapper th:first-child,
.v-data-table-with-borders .v-data-table__wrapper td:first-child {
  border-left: 1px solid #e0e0e0;
}

.v-data-table-with-borders .v-data-table__wrapper tr {
  border-bottom: 1px solid #e0e0e0;
}
.parent-header {
  background-color: #f5f5f5;
  font-weight: bold;
}
.child-header.sortable {
  cursor: pointer;
}

.child-header.sortable:hover {
  text-decoration: underline;
}
tr:hover a .primary--text {
  color: white !important;
}
</style>
